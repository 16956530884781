import type { FC } from 'react';
import { useCallback } from 'react';
import { addSportFavoritesV2Request, removeSportFavoritesV2Request } from '@mwl/core-lib';
import { useTypedDispatch } from '@/hooks';
import { ButtonFavorites } from '../ButtonFavorites/ButtonFavorites';
import type { SportButtonFavoritesProps } from './SportButtonFavorites.types';
import styles from './SportButtonFavorites.module.scss';
const SportButtonFavorites: FC<SportButtonFavoritesProps> = ({
  lineId,
  isFavorite,
  className,
  analytics,
  ...rest
}) => {
  const dispatch = useTypedDispatch();
  const handleToggleFavorites = useCallback(async (newValue: boolean) => {
    if (newValue) {
      dispatch(addSportFavoritesV2Request({
        lineIds: [lineId]
      }));
    } else {
      dispatch(removeSportFavoritesV2Request({
        lineIds: [lineId]
      }));
    }
  }, [dispatch, lineId]);
  return <ButtonFavorites onClick={handleToggleFavorites} value={isFavorite} color="red" className={cn(className, styles.root)} analytics={analytics} {...rest} data-sentry-element="ButtonFavorites" data-sentry-component="SportButtonFavorites" data-sentry-source-file="SportButtonFavorites.tsx" />;
};
export { SportButtonFavorites };